<template>
  <TTModuleMenu
    hide-sign-out
    :items-tree="menu"
  />
</template>

<script>
/* eslint-disable vue/require-name-property */

export default {
  inject: ['Names'],
  data() {
    return {
      menu: [
        [
          {
            title: 'Цвета',
            to: { name: this.Names.R_UIKIT_COLORS },
            icon: 'fal fa-palette',
          },
          {
            title: 'Typography',
            to: { name: this.Names.R_UIKIT_TYPOGRAPHY },
            icon: 'fal fa-text',
          },
          {
            title: 'Classes',
            to: { name: this.Names.R_UIKIT_CLASSES },
            icon: 'fab fa-css3-alt',
          },
        ],
        [
          {
            title: 'Кнопки',
            to: { name: this.Names.R_UIKIT_BTN },
            icon: 'fas fa-rectangle-landscape',
          },
          {
            title: 'Чекбоксы и радиобаттоны',
            to: { name: this.Names.R_UIKIT_CHECKBOX },
            icon: 'fal fa-radio-alt',
            badge: true,
          },
        ],
        [
          {
            title: 'Формы',
            icon: 'fab fa-wpforms',
            children: [
              {
                title: 'Текстовые поля',
                to: { name: this.Names.R_UIKIT_TEXTFIELD },
                icon: 'fal fa-align-justify',
              },
              {
                title: 'Textarea',
                to: { name: this.Names.R_UIKIT_TEXTAREA },
                icon: 'fal fa-paragraph',
                badge: true,
              },
              {
                title: 'Select',
                to: { name: this.Names.R_UIKIT_SELECT },
                icon: 'fal fa-hand-pointer',
              },
              {
                title: 'Autocomplete',
                to: { name: this.Names.R_UIKIT_AUTOCOMPLETE },
                icon: 'fal fa-list-ul',
                badge: true,
              },
            ],
          },
        ],
        [
          {
            title: 'Tables',
            to: { name: this.Names.R_UIKIT_DATA_TABLE },
            icon: 'fal fa-th',
            badge: true,
          },
          {
            title: 'Алерты',
            to: { name: this.Names.R_UIKIT_ALERT },
            icon: 'fal fa-info',
          },
          {
            title: 'Нотификации',
            to: { name: this.Names.R_UIKIT_NOTIFY },
            icon: 'fal fa-bars',
            badge: true,
          },
          {
            title: 'Тулбары',
            to: { name: this.Names.R_UIKIT_TOOLBAR },
            icon: 'fal fa-tools',
            badge: true,
          },
          {
            title: 'Chip',
            to: { name: this.Names.R_UIKIT_CHIP },
            icon: 'fal fa-circle',
            badge: true,
          },
          {
            title: 'Хлебные крошки',
            to: { name: this.Names.R_UIKIT_BREADCRUMBS },
            icon: 'fal fa-bread-slice',
            badge: true,
          },
          {
            title: 'Аватары',
            to: { name: this.Names.R_UIKIT_AVATAR },
            icon: 'far fa-user',
          },
          {
            title: 'Сокращения',
            to: { name: this.Names.R_UIKIT_PROMPT },
            icon: 'fal fa-ellipsis-h',
            badge: true,
          },
        ],
        [
          {
            title: 'Grid',
            to: { name: this.Names.R_UIKIT_GRID },
            icon: 'fal fa-th',
            badge: true,
          },
          {
            title: 'Holy Grail Layout',
            to: { name: this.Names.R_UIKIT_HOLY_GRAIL_LAYOUT },
            icon: 'fal fa-angel',
          },
        ],
      ],
    };
  },
};
</script>
